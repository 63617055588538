import { useDeleteEntity } from 'hooks/useDeleteEntity'
import { useManyHousingUnitStats } from 'hooks/useHousingUnitStats'
import React from 'react'
import { EntityTypename, ROUTES } from 'src/constants'
import { useIsOverridableAdmin, useIsOverridablePrivileged } from 'src/contexts/authContext'
import { areYouSure } from 'utils/areYouSure'
import { EntityLink } from './EntityLink'
import { FieldStep, HousingUnitQuickStats } from './HousingUnitQuickStats'
import { IconButton, IconLink } from './IconButton'

export function HousingUnitList({
	items,
}: {
	items: {
		id: string
		name: string
	}[]
}) {
	const isAdmin = useIsOverridableAdmin()
	const isPrivileged = useIsOverridablePrivileged() && !isAdmin

	const [del] = useDeleteEntity('deleteHousingUnit', ['myHousingUnits'])
	const statsFields: FieldStep[] = isAdmin
		? ['configurator', 'waitingForContractor', 'waitingForClient', 'approved', 'finalized']
		: isPrivileged
		? ['configurator', 'waitingForContractor', 'waitingForClient', 'approved', 'finalized']
		: []

	const stats = statsFields.length
		? // eslint-disable-next-line react-hooks/rules-of-hooks
		  useManyHousingUnitStats(
				items.map((it) => it.id),
				statsFields?.includes('configurator')
		  )
		: null

	return (
		<table className="table table-hover">
			<thead className="thead thead-fade">
				<tr>
					<th scope="col" className="text-nowrap"></th>
					<th scope="col" className="text-nowrap">
						Byt
					</th>
					{isAdmin && (
						<>
							<th scope="col" className="text-nowrap">
								Konfigurátor
							</th>
							<th scope="col" className="text-nowrap">
								Ke zpracování
							</th>
							<th scope="col" className="text-nowrap">
								Ke schválení
							</th>
							<th scope="col" className="text-nowrap">
								Schváleno
							</th>
							<th scope="col" className="text-nowrap">
								Finalizováno
							</th>
							<th scope="col" className="text-nowrap text-right"></th>
						</>
					)}
					{isPrivileged && (
						<>
							<th scope="col" className="text-nowrap">
								Konfigurátor
							</th>
							<th scope="col" className="text-nowrap">
								Čeká na zpracování
							</th>
							<th scope="col" className="text-nowrap">
								Čeká na klienta
							</th>
							<th scope="col" className="text-nowrap">
								Schváleno klientem
							</th>
							<th scope="col" className="text-nowrap">
								Finalizováno
							</th>
						</>
					)}
				</tr>
			</thead>
			<tbody>
				{items.map((item) => (
					<tr key={item.id}>
						<td className="td-minify"></td>
						<th scope="row">
							<EntityLink type={EntityTypename.HOUSING_UNIT} id={item.id}>
								{item.name}
							</EntityLink>
						</th>
						{isAdmin && (
							<>
								<HousingUnitQuickStats
									hideIcons
									fields={statsFields}
									id={item.id}
									stats={stats?.[item.id]}
								/>
								<td className="text-right">
									<IconLink
										name="settings"
										route={ROUTES.HOUSING_UNIT(item.id, 'edit')}
									/>
									<IconButton
										name="delete"
										onClick={() => areYouSure().then(() => del(item.id))}
									/>
								</td>
							</>
						)}
						{isPrivileged && (
							<>
								<HousingUnitQuickStats
									hideTotals
									hideIcons
									fields={statsFields}
									id={item.id}
									stats={stats?.[item.id]}
								/>
							</>
						)}
					</tr>
				))}
			</tbody>
		</table>
	)
}
