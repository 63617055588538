import { $ } from 'generated/content'
import { flatMap } from 'lodash'
import { useQuery } from 'react-query'
import { ConfiguratorState, detectConfiguratorState } from 'utils/detectConfiguratorState'
import { createTreeList } from './createTreeList'
import { useUserContent } from './useContember'

interface DocumentStats {
	approved: number
	waitingForClient: number
	waitingForContractor: number
	finalizedWaiting: number
	finalizedApproved: number
	finalizedDenied: number
}

export interface HousingUnitStats {
	approving: DocumentStats
	configurator: ConfiguratorState
}

interface Folder {
	documents?: {
		isFinalization: boolean | null
		revisions?: {
			id: string
			approved: boolean | null
		}[]
	}[]
	children?: Folder[]
}

const extractRevisions = (
	folders: Folder[]
): { approved: boolean | null; isFinalization: boolean | null }[] => {
	return [
		...flatMap(
			flatMap(folders, (it) => it.documents ?? []),
			(doc) =>
				(doc.revisions ?? []).map((rev) => ({
					approved: rev.approved,
					isFinalization: doc.isFinalization,
				}))
		),
		...flatMap(folders, (it) => extractRevisions(it.children || [])),
	]
}

export function useHousingUnitStats(
	id: string,
	withConfigurator = true
): HousingUnitStats | undefined {
	return useManyHousingUnitStats([id], withConfigurator)?.[id]
}

export function useManyHousingUnitStats(ids: string[], withConfigurator = true) {
	const content = useUserContent()

	return useQuery(
		['useHousingUnitStats', ids],
		async (): Promise<Record<string, HousingUnitStats>> => {
			const housingUnits = await content.query(
				{
					listHousingUnit: [
						{
							filter: { id: { in: $`ids` } },
						},
						{
							id: true,
							rootFolder: [{}, createTreeList()],
							waitingForConfigurator: true,
							configuratorBoilerPlates: [{}, { id: true }],
							...(withConfigurator
								? {
										configuratorInputs: [
											{},
											{
												id: true,
											},
										],
								  }
								: {}),
						},
					],
				},
				{ ids }
			)
			const result: Record<string, HousingUnitStats> = {}
			for (const unit of housingUnits.listHousingUnit) {
				const configurator: ConfiguratorState = withConfigurator
					? detectConfiguratorState(unit)
					: 'none'

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const revisions = extractRevisions(unit.rootFolder ? [unit.rootFolder as any] : [])
				const approving: DocumentStats = {
					approved: 0,
					waitingForClient: 0,
					waitingForContractor: 0,
					finalizedWaiting: 0,
					finalizedApproved: 0,
					finalizedDenied: 0,
				}
				revisions.forEach((rev) => {
					switch (rev.approved) {
						case true:
							if (rev.isFinalization) {
								approving.finalizedApproved++
							} else {
								approving.approved++
							}
							break
						case false:
							if (rev.isFinalization) {
								approving.finalizedDenied++
							} else {
								approving.waitingForContractor++
							}
							break
						case null:
							if (rev.isFinalization) {
								approving.finalizedWaiting++
							} else {
								approving.waitingForClient++
							}
							break
					}
				})
				result[unit.id] = {
					approving,
					configurator,
				}
			}

			return result
		}
	)?.data
}
