import { ValueTypes } from 'generated/content'

export function createTreeList(depth = 5): ValueTypes['Folder'] {
	return {
		documents: [
			{
				filter: { approvable: { eq: true } },
			},
			{
				isFinalization: true,
				revisions: [
					{
						filter: { next: { not: { id: { isNull: false } } } },
					},
					{
						id: true,
						approved: true,
					},
				],
			},
		],
		...(depth > 0 ? { children: [{}, createTreeList(depth - 1)] } : {}),
	}
}
