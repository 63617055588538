export type ConfiguratorState = 'none' | 'waiting' | 'finished'

export function detectConfiguratorState(unit: {
	waitingForConfigurator: boolean
	configuratorBoilerPlates: unknown[]
	configuratorInputs?: undefined | unknown[]
}): ConfiguratorState {
	let configurator: ConfiguratorState = 'none'
	if (unit.configuratorBoilerPlates.length) {
		configurator = 'waiting'

		if (!unit.waitingForConfigurator && (unit.configuratorInputs?.length ?? 0) > 0) {
			configurator = 'finished'
		}
	}
	return configurator
}
