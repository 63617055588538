import { HousingUnitStats } from 'hooks/useHousingUnitStats'
import React from 'react'
import { cn } from 'utils/cn'
import { Icon, IconName } from './Icon'
import s from './QuickStats.module.sass'

export type FieldStep =
	| 'approved'
	| 'waitingForContractor'
	| 'waitingForClient'
	| 'finalized'
	| 'configurator'

function FinalizationStatus(props: {
	approving: { finalizedWaiting: number; finalizedApproved: number; finalizedDenied: number }
	approved?: boolean | null
	anyApproved?: boolean | null
}) {
	const approved = props.approving.finalizedApproved
	const denied = props.approving.finalizedDenied
	const waiting = props.approving.finalizedWaiting

	const approvedMiniIcon = props.anyApproved && !approved

	const miniIcon = approvedMiniIcon ? (
		<span className={s.MiniIcon}>
			<span className="text-success">
				<Icon name="approved" />
			</span>
		</span>
	) : null

	return (
		<span className={s.FinalizationStatus}>
			{!!waiting && (
				<span className="text-dark">
					{miniIcon}
					<span className={s.MainIcon}>
						<span className="text-light">
							<Icon name="waitingApproved" />
						</span>
					</span>
					{`${waiting > 1 ? `${waiting} ` : ''} čeká`}
				</span>
			)}
			{!!approved && (
				<span className="text-success">
					{miniIcon}
					<span className={s.MainIcon}>
						<Icon name="approved" />
					</span>
					{approved > 1 && `${approved} schváleno`}
				</span>
			)}
			{!!denied && (
				<span className="text-danger">
					{miniIcon}
					<span className={s.MainIcon}>
						<Icon name="disapproved" />
					</span>
					{denied > 1 && `${denied} denied`}
				</span>
			)}
		</span>
	)
}

export function HousingUnitQuickStatsStatic(props: {
	hideTotals?: boolean
	hideIcons?: boolean
	fields: FieldStep[]
	configurator?: unknown
	approving?: {
		approved: number
		waitingForClient: number
		waitingForContractor: number
		finalizedWaiting: number
		finalizedApproved: number
		finalizedDenied: number
	}
	finalizationApproved?: boolean | null
	finalizationAnyApproved?: boolean | null
}) {
	const { hideTotals, hideIcons } = props

	const fields: FieldStep[] = props.fields ?? [
		'approved',
		'waitingForContractor',
		'waitingForClient',
	]

	const fieldIcons: Record<FieldStep, IconName> = {
		configurator: 'approved',
		approved: 'approved',
		waitingForContractor: 'disapproved',
		waitingForClient: 'waitingApproved',
		finalized: 'approved',
	}

	const fieldClasses: Record<FieldStep, string> = {
		configurator: 'text-success',
		approved: 'text-success',
		waitingForContractor: 'text-danger',
		waitingForClient: 'text-dark',
		finalized: 'text-dark',
	}

	const fieldShowTotals: Partial<Record<FieldStep, boolean>> = {
		approved: true,
		waitingForContractor: false,
		waitingForClient: false,
	}

	const total = props.approving
		? props.approving.approved +
		  props.approving.waitingForContractor +
		  props.approving.waitingForClient
		: 0

	// TODO: když jsou všechny approved, tak approved sekci označit jako .text-success

	return (
		<>
			{props.approving ? (
				<>
					{fields.map((f) => {
						return (
							props.approving && (
								<td key={f} className="td-minify">
									{f === 'configurator' ? (
										<>
											{props.configurator === 'none' ? (
												''
											) : props.configurator === 'waiting' ? (
												<span
													className="text-light"
													title="Čeká na vyplnění">
													<Icon name="waitingApproved" />
												</span>
											) : (
												<span className="text-success" title="Vyplněno">
													<Icon name="approved" />
												</span>
											)}
										</>
									) : f === 'finalized' ? (
										<>
											<FinalizationStatus
												approving={props.approving}
												approved={props.finalizationApproved}
												anyApproved={props.finalizationAnyApproved}
											/>
										</>
									) : (
										<span
											className={cn(
												props.approving[f] > 0
													? fieldClasses[f]
													: 'text-dark',
												s.Item
											)}>
											{!hideIcons && <Icon name={fieldIcons[f]} />}
											{props.approving[f]}
											{!hideTotals && fieldShowTotals[f] && ` / ${total}`}
										</span>
									)}
								</td>
							)
						)
					})}
				</>
			) : (
				<>
					{fields.map((f) => (
						<td key={f} className="td-minify"></td>
					))}
				</>
			)}
		</>
	)
}

export function HousingUnitQuickStats(props: {
	id: string
	hideTotals?: boolean
	hideIcons?: boolean
	fields: FieldStep[]
	stats?: HousingUnitStats
}) {
	const { hideTotals, hideIcons } = props

	return (
		<HousingUnitQuickStatsStatic
			approving={props.stats?.approving}
			configurator={props.stats?.configurator}
			hideTotals={hideTotals}
			hideIcons={hideIcons}
			fields={props.fields}
		/>
	)
}
